import React from 'react'
import CroppedImage from '../components/CroppedImage';
import PageHelmet from '../components/PageHelmet';
import Layout from '../components/layout';
import { graphql } from 'gatsby'

const Services = (props) => (
    <Layout>
        <PageHelmet title="Services" />

        <div id="main" className="alt">
            <section id="services">
                <div className="inner">
                    <header className="major">
                        <h1>Services</h1>
                    </header>
                    <p>Littlebirds offer comprehensive assessment and therapy services for children from birth onwards.</p>
                    <CroppedImage sizes={props.data.dinoKid.childImageSharp.fluid} />
                    <h2>Speech and Language Assessment</h2>
                    <p>We provide a comprehensive assessment of a childs’ speech, language and overall communication skills using a mix of standardised assessments and observational measures.
                    Assessment is typically conducted over 1-2 sessions and is a joint collaboration between family, the child and the Speech and Language Therapist. Feedback is given regarding results and recommendations regarding enrolment in therapy is provided.</p>
                    <h2>Literacy Assessment</h2>
                    <p>We provide a comprehensive assessment for reading difficulties using both standardised and benchmark assessments. Our assessment focuses primarily on the underlying skills required for reading such as Phonemic Awareness as well as current knowledge and needs in the area of sound-letter relationships. These assessments are not designed to diagnose dyslexia but do allow us to identify the skills and knowledge still needing to be acquired.</p>
                    <h2>Intervention / Therapy Services</h2>
                    <p>Following assessment, therapy can be provided on a weekly or fortnightly basis. Sessions are typically 30 - 45 minutes long depending on the individual circumstances. Goals are individualised to the child and since we want therapy to be fun, we use many different games and activities in a relaxed atmosphere so your child is enjoying themselves while they learn new skills.</p>
                    <p>Parents/Caregivers are expected to participate in sessions and home practise opportunities are discussed. At Littlebirds Speech, Language and Literacy we feel family are key to progress so home practise is vital to helping your child succeed.</p>
                    <p>Our reading intervention is based on current research and we use a systematic, structured synthetic phonics approach alongside explicit instruction in Phonemic Awareness. Nicole is trained to provide the Sounds-Write program which is used throughout the UK and Australia as a proven highly effective intervention. Sessions are typically 45 minutes to 1 hour per week with additional activities provided for home practise.</p>

                </div>
            </section>
            <section id="fees">
                <div className="inner">
                    <header className="major">
                        <h1>Fees</h1>
                    </header>
                    <CroppedImage sizes={props.data.puppetKid.childImageSharp.fluid} />

                    <div className="fees-table">
                        <div className="table-row long">
                            <div className="service">Initial Comprehensive Assessment</div>
                            <div className="fee">$140 - $300</div>
                        </div>
                        <div className="table-row long">
                            <div className="service">Reports (optional at parent request)</div>
                            <div className="fee">$100 per hour</div>
                        </div>
                        <div className="table-row">
                            <div className="service">Review Assessment</div>
                            <div className="fee">$100</div>
                        </div>
                        <div className="table-row-spacer"></div>
                        <div className="table-row">
                            <div className="service">Therapy (30 minutes)</div>
                            <div className="fee">$70</div>
                        </div>
                        <div className="table-row">
                            <div className="service">Therapy (45 minutes)</div>
                            <div className="fee">$85</div>
                        </div>
                    </div>
                    <p>Payments can be made by cash or direct transfer</p>

                </div>
            </section>
        </div>

    </Layout>
)

export default Services

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                ... on ImageSharp {
                    resize(width: 125, height: 125, rotate: 180) {
                    src
                    }
                }
                }
            }
        },
        dinoKid: file(
            relativePath: { regex: "/dinoKid.jpg/" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 1240
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          },
          puppetKid: file(
            relativePath: { regex: "/puppetKid.jpg/" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 1240
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          },
    }
`;